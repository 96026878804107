import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "login",
    component: () => import("@/components/LoginPlataforma.vue"),
  },
  {
    path: "/secuencia-didactica",
    name: "secuencia",
    component: () => import("@/components/SecuenciaDidactica.vue"),
  },
  {
    path: "/asignaturas",
    name: "asignaturas",
    component: () => import("@/components/AsignaturasLista.vue"),
  },
  {
    path: "/tareas/:id",
    name: "tareas",
    component: () => import("@/components/TareasPlataforma.vue"),
  },
  {
    path: "/tarea",
    name: "tarea",
    component: () => import("@/components/TareaVista.vue"),
  },
  {
    path: "/calificaciones",
    name: "calificaciones",
    component: () => import("@/components/CalificacionesPlataforma.vue"),
  },
  {
    path: "/retroalimentaciones",
    name: "retroalimentaciones",
    component: () => import("@/components/RetroalimentacionesPlataforma.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

export default router;
